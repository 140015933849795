<template>
  <div v-if="isActive === false" class="container">
    <div class="header-wrapper">
      <router-link to="/">
        <div class="header-logo"></div>
      </router-link>
      <nav>
        <div v-if="$route.name === 'home'">
          <a href="#function" ref="function">Function</a>
        </div>

        <div v-if="$route.name !== 'home'" @click="$refs.function.click()">
          <router-link to="/">Function</router-link>
        </div>


        <div v-if="$route.name === 'home'">
          <a href="#whyProbos" ref="whyProbos">Why Probos</a>
        </div>

        <div v-if="$route.name !== 'home'" @click="$refs.whyProbos.click()">
          <router-link to="/">Why Probos</router-link>
        </div>


        <div v-if="$route.name === 'home'">
          <a href="#blockchain" ref="blockchains">Blockchains</a>
        </div>

        <div v-if="$route.name !== 'home'" @click="$refs.blockchains.click()">
          <router-link to="/">Blockchains</router-link>
        </div>
        <button class="menu" @click="isActive = !isActive"></button>
      </nav>
    </div>

  </div>
  <div v-if="isActive === true" class="mobile-menu">
    <div class="top-contant">
      <div class="header-logo"></div>
      <button>
        <div class="close" @click="isActive = !isActive"></div>
      </button>
    </div>
    <div class="bottom-content">
      <div>
        <a href="#function" @click="isActive = !isActive">Function</a>
      </div>
      <div>
        <a href="#why-probos" @click="isActive = !isActive">Why Probos</a>
      </div>
      <div>
        <a href="#support" @click="isActive = !isActive">Blockchains</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeaderComponent",
  components: {
  },
  data() {
    return {
      isActive: false
    };
  },
}
</script>

<style scoped>

</style>