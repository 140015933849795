<template>
  <div class="footer">
    <div class="container">
      <a href="/" class="footer-logo" style="text-decoration: none">
        <p>PROBOS</p>
      </a>
      <div class="footer-line">
        <div class="privacy mpv">
          <router-link to="/Privacy">
            Privacy Policy
          </router-link>
          <router-link to="/TermOfUse">
            Terms of use
          </router-link>
        </div>
        <div class="social mpv">
          <a target="_blank" href="https://t.me/Probos_group" class="telegram"></a>
          <a target="_blank" href="https://t.me/probos_info" class="telegram"></a>
          <a target="_blank" href="https://twitter.com/probos_wallet" class="twitter"></a>
        </div>
        <div class="download">
          <a href="https://apps.apple.com/ua/app/probos-wallet/id1615751706?l=uk" target="_blank">
            <div class="apple-icon"></div>
            <div class="download-info">
              <p>Download on the</p>
              <p>App Store</p>
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.elephantslab.probos&hl=uk&gl=US" target="_blank">
            <div class="google-icon"></div>
            <div class="download-info">
              <p>Download on the</p>
              <p>Google Play</p>
            </div>
          </a>
        </div>
        <div class="privacy">
            <router-link to="/Privacy">
              Privacy Policy
            </router-link>
            <router-link to="/TermOfUse">
              Terms of use
            </router-link>
        </div>
        <div class="social">
          <a target="_blank" href="http://instagram.com/proboswallet" class="instagram"></a>
          <a target="_blank" href="https://t.me/Probos_group" class="telegram"></a>
          <a target="_blank" href="http://facebook.com/proboswallet" class="facebook"></a>
          <a target="_blank" href="https://t.me/probos_info" class="telegram"></a>
          <a target="_blank" href="https://twitter.com/probos_wallet" class="twitter"></a>
        </div>
      </div>
    </div>
    <div class="unde-footer">
      <div class="container footers">
        <p>© 2022 Probos. All rights reserved</p>
        <div>
          <span>developed by</span>
          <a  target="_blank" href="https://elephantslab.com/" class="logo"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  async mounted(){
    window.scrollTo( {top: 0, behavior: 'instant'});
  }
}
</script>

<style scoped>

</style>